import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useState } from "react";
import {
  heading100,
  heading100Light,
  heading150,
  heading200,
  heading300,
  heading50,
  paragraphTextMedium,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import useClickOutside from "../../utils/hooks/useOutsideClick";
import LinkedInIcon from "../shared/SocialsBar/assets/linkedin.inline.svg";
import CloseIcon from "./assets/close.inline.svg";
import ArrowIcon from "../shared/Button/arrow_right.inline.svg";

const heading = `MEET THE TEAM`;
const title = `We are an expert team of materials scientists`;
const byline = `Plastometrex was founded by a team of materials scientists, engineers, computational modellers and scientific programmers, with strong industrial links and a proven track record of delivering high value technical consultancy projects. Our ultimate objective is to make mechanical materials testing faster, simpler, cheaper and more flexible.`;

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/teamMembers/" } }
        sort: { fields: [frontmatter___priority], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              name
              position
              linkedIn
              priority
              photo {
                childImageSharp {
                  gatsbyImageData(width: 390)
                }
              }
            }
            html
          }
        }
      }
    }
  `);

  const [activeMember, setActiveMember] = useState<any>(null);
  const [hoveredMember, setHoveredMember] = useState<any>(null);
  const ref = useClickOutside(() => setActiveMember(null));

  return (
    <Container>
      <TextContent>
        <Heading>{heading}</Heading>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
      </TextContent>
      <TeamContainer>
        {data.allMarkdownRemark.edges.map((edge: any) => {
          const edgeData = edge.node.frontmatter;
          const image = getImage(
            edgeData.photo.childImageSharp
          ) as IGatsbyImageData;
          return (
            <TeamMemberContainer
              key={edgeData.name}
              onMouseOver={() => setHoveredMember(edge)}
              onMouseLeave={() => setHoveredMember(null)}
            >
              <MemberImageWrapper onClick={() => setActiveMember(edge)}>
                {hoveredMember === edge && (
                  <MemberMouseOver>
                    Read Bio
                    <IconWrapper>
                      <ArrowIcon />
                    </IconWrapper>
                  </MemberMouseOver>
                )}
                <GatsbyImage image={image} alt={edgeData.name} />
              </MemberImageWrapper>
              <MemberName>{edgeData.name}</MemberName>
              <MemberPosition>{edgeData.position}</MemberPosition>
              <LinkedInWrapper
                href={edgeData.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </LinkedInWrapper>
            </TeamMemberContainer>
          );
        })}
      </TeamContainer>
      {activeMember && (
        <PopoutWrapper>
          <PopoutContent ref={ref}>
            <CloseIconWrapper onClick={() => setActiveMember(null)}>
              <CloseIcon />
            </CloseIconWrapper>
            <PopoutName>{activeMember.node.frontmatter.name}</PopoutName>
            <PopoutPosition>
              {activeMember.node.frontmatter.position}
            </PopoutPosition>
            <MarkdownWrapper
              dangerouslySetInnerHTML={{
                __html: activeMember.node.html,
              }}
            />
          </PopoutContent>
        </PopoutWrapper>
      )}
    </Container>
  );
};

export default Team;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 1110,
  paddingTop: 32,
  "@md": {
    paddingTop: 76,
  },
});
const TextContent = styled("div", {
  "@lg": {
    marginLeft: 20,
  },
});
const PopoutWrapper = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: "100vh",
  width: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: 15,
  overflowY: "auto",
});
const PopoutContent = styled("div", {
  width: "95%",
  margin: "30px auto",
  maxWidth: 841,
  backgroundColor: "$white",
  padding: 20,
  borderRadius: 12,
  position: "relative",
});
const CloseIconWrapper = styled("div", {
  position: "absolute",
  top: 10,
  right: 10,
  width: 30,
  height: 30,
  padding: 5,
  cursor: "pointer",
  "& svg": {
    width: "100%",
  },
});
const PopoutName = styled("div", {
  ...heading100,
  marginBottom: 20,
});
const PopoutPosition = styled("div", {
  ...heading50,
  marginBottom: 20,
});
const MarkdownWrapper = styled("div", {
  "& p": {
    ...paragraphTextSmall,
    color: "$black100",
  },
});
const TeamContainer = styled("div", {
  paddingTop: 75,
  display: "flex",
  flexWrap: "wrap",
  "@lg": {
    justifyContent: "flex-start",
  },
});
const TeamMemberContainer = styled("div", {
  maxWidth: 390,
  width: "100%",
  margin: "auto",
  marginBottom: 50,
  "@md": {
    width: "48%",
  },
  "@lg": {
    width: "22%",
    maxWidth: 255,
    marginLeft: "1.5%",
    marginRight: "1.5%",
  },
});
const MemberImageWrapper = styled("div", {
  borderRadius: 0,
  overflow: "hidden",
  position: "relative",
  "& img": {
    borderRadius: 0,
    overflow: "hidden",
  },
});
const LinkedInWrapper = styled("a", {
  cursor: "pointer",
  width: 32,
  borderRadius: "50%",
  overflow: "hidden",
  "& svg": {
    "& *": {
      fill: "$blue100",
    },
    "& .background-circle": {
      fill: "$blue100",
      opacity: 0.1,
    },
  },
  "&:hover": {
    "& svg": {
      "& *": {
        fill: "$white",
      },
      "& .background-circle": {
        fill: "$blue100",
        opacity: 1,
      },
    },
  },
});
const Heading = styled("h6", {
  ...paragraphTextSmall,
  color: "$blue100",
  margin: 0,
});
const Title = styled("h2", {
  ...heading150,
  margin: 0,
  marginBottom: 24,
  maxWidth: 468,
  "@md": {
    ...heading300,
  },
});
const Byline = styled("p", {
  ...paragraphTextMedium,
  margin: 0,
  maxWidth: 803,
});

const MemberName = styled("p", {
  ...heading100,
  marginTop: 12,
  marginBottom: 4,
});
const MemberPosition = styled("p", {
  ...heading50,
  fontWeight: 500,
  color: "$black55",
  margin: 0,
  marginBottom: 12,
});
const MemberMouseOver = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(26, 26, 26, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 3,
  fontWeight: 700,
  fontFamily: "$opensans",
  color: "$white",
  fontSize: 20,
  cursor: "pointer",
});

const IconWrapper = styled("div", { marginLeft: 6 });
