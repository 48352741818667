import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { heading200, heading300 } from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const title = `Partners, Investors and Collaborators`;

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "team/partners.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
    }
  `);

  const image = getImage(data.render) as IGatsbyImageData;
  return (
    <Container>
      <Title>{title}</Title>
      <ItemsContainer>
        <GatsbyImage image={image} alt="" />
      </ItemsContainer>
    </Container>
  );
};

export default Partners;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: 61,
  paddingTop: 61,
});
const ItemsContainer = styled("div", {});
const Title = styled("h4", {
  ...heading200,
  marginBottom: 50,
  textAlign: "center",
  "@md": {
    ...heading300,
  },
});
// const Container = styled("div", {})
// const Container = styled("div", {})
