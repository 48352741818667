import React from "react";
import Layout from "../components/Layout";
import Careers from "../components/team/Careers";
import Partners from "../components/team/Partners";
import Team from "../components/team/Team";

const seo = {
  title: "Team",
  description:
    "Plastometrex was founded by a team of materials scientists from The University of Cambridge. Dr James Dean, Prof. Bill Clyne, Dr. Jimmy Campbell, Dr Max Burley, Dr. Mike Coto",
};

const TeamPage = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <Team />
      <Partners />
      <Careers />
    </Layout>
  );
};

export default TeamPage;
